<template>
  <gmap-autocomplete
    @place_changed="getAddressData"
    :componentRestrictions="{country: country}"
    :placeholder="placeholder"
    :value="getValue"
    :types="types"
    @input="changeInputValue"
  />
</template>

<script>
  export default {
    props: {
      placeholder: {
        type: String,
        default: 'Enter the location'
      },
      country: {
        type: String,
        default: 'us'
      },
      types: {
        type: Array,
        default () { return ['(regions)'] }
      },
      getLocationData: {
        type: Function,
        default: () => {}
      },
      value: {
        default: ''
      }
    },
    computed: {
      getValue () {
        if (typeof this.value === 'object' && this.value !== null) {
          return this.value.formatted_address
        }
        return this.value
      }
    },
    methods: {
      getAddressData (res) {
        const location = {
          formatted_address: res?.formatted_address,
          place_id: res?.place_id,
          lat: res?.geometry?.location?.lat(),
          lng: res?.geometry?.location?.lng()
        }

        this.getLocationData(location)
        this.$emit('change', location);
      },
      changeInputValue(event) {
        if (event.target.value !== this.getValue) {
          this.getLocationData('')
          this.$emit('input', event.target.value)
        }
      }
    }
  }

</script>
